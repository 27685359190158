<template>
  <div class="past">
    <van-tabs>
      <van-tab title="第一周">
        <img class="back" src="~@/assets/images/返回.png" alt="" width="20px" height="18px" @click="back">
        <div class="about-container">
          <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
          </div>
          <div class="app-container">
            <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
            <div class="container-top">
              <ul>
                <li class="ul-chidern1">
                  <div class="ul-chidern1-conent">
                    <img src="https://picsum.photos/54/54" alt="">
                    <p>昵称</p>
                  </div>
                  <span>观看量</span>
                </li>
                <li class="ul-chidern2">
                  <div class="ul-chidern2-conent">
                    <img src="https://picsum.photos/id/237/54/54" alt="">
                    <p>昵称</p>
                  </div>
                  <span>观看量</span>
                </li>
                <li class="ul-chidern3">
                  <div class="ul-chidern3-conent">
                    <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                    <p>昵称</p>
                  </div>
                  <span>观看量</span>
                </li>
              </ul>
            </div>
            <div class="container-center">
              <ul>
                <li>排名</li>
                <li>头像</li>
                <li>主播名称</li>
                <li>观看量</li>
              </ul>
            </div>
            <div class="container-bottom">
              <ul>
                <li v-for="item in items" :key="item.ranking" >
                  <span>{{item.ranking}}</span>
                  <span class="conainer-bottom-pic">
                    <img :src="item.image" alt='' />
                  </span>
                  <span>{{ item.name }}</span>
                  <span>{{ item.watch }}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </van-tab>
      <van-tab title="第二周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img src="https://picsum.photos/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in messages" :key="item.ranking" >
                <span>{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.image" alt='' />
                </span>
                <span>{{ item.name }}</span>
                <span>{{ item.watch }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第三周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img src="https://picsum.photos/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in messages1" :key="item.ranking" >
                <span>{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.image" alt='' />
                </span>
                <span>{{ item.name }}</span>
                <span>{{ item.watch }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第四周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img src="https://picsum.photos/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in messages2" :key="item.ranking" >
                <span>{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.image" alt='' />
                </span>
                <span>{{ item.name }}</span>
                <span>{{ item.watch }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第五周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img src="https://picsum.photos/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in messages3" :key="item.ranking" >
                <span>{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.image" alt='' />
                </span>
                <span>{{ item.name }}</span>
                <span>{{ item.watch }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第六周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img src="https://picsum.photos/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in messages4" :key="item.ranking" >
                <span>{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.image" alt='' />
                </span>
                <span>{{ item.name }}</span>
                <span>{{ item.watch }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第七周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img src="https://picsum.photos/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in messages5" :key="item.ranking" >
                <span>{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.image" alt='' />
                </span>
                <span>{{ item.name }}</span>
                <span>{{ item.watch }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
      <van-tab title="第八周">
        <div class="container-title">
          <img src="~@/assets/images/container-pic.png" alt="滑动"> 
          <span>左滑查看更多</span>
        </div>
        <div class="app-container">
          <img class="tab-pic" src="~@/assets/images/tab-pic.png" alt="直播人数榜">
          <div class="container-top">
            <ul>
              <li class="ul-chidern1">
                <div class="ul-chidern1-conent">
                  <img src="https://picsum.photos/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                  <p>昵称</p>
                </div>
                <span>观看量</span>
              </li>
            </ul>
          </div>
          <div class="container-center">
            <ul>
              <li>排名</li>
              <li>头像</li>
              <li>主播名称</li>
              <li>观看量</li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in messages6" :key="item.ranking" >
                <span>{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.image" alt='' />
                </span>
                <span>{{ item.name }}</span>
                <span>{{ item.watch }}</span>
              </li>
            </ul>
          </div>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>


export default {
  name: 'PageHome',
  data() {
    return {
      // 第一周
      items: [ // 定义一个数组
        {
          ranking: 4,
          name: '冰鲜柠檬水你喝嘛',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 5,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 6,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking: 7,
          name: '冰鲜柠檬水你喝嘛',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 8,
          name: '草莓',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 9,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking: 10,
          name: '冰鲜柠檬水你喝嘛',
          watch: 12238980,
          image: 'https://picsum.photos/seed/picsum/32/32',
        }
      ],
      // 第二周
      messages :[
        {
          ranking: 4,
          name: '崎岖扭扭蛋',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 5,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 6,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking:7,
          name: '冰鲜柠檬水你喝嘛',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 8,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 9,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking: 10,
          name: '冰鲜柠檬水你喝嘛',
          watch: 12238980,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
      ],
      // 第三周
      messages1 :[
        {
          ranking: 4,
          name: '崎岖扭扭蛋',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 5,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 6,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking:7,
          name: '冰鲜柠檬水你喝嘛',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 8,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 9,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking: 10,
          name: '冰鲜柠檬水你喝嘛',
          watch: 12238980,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
      ],
      // 第四周
      messages2 :[
        {
          ranking: 4,
          name: '崎岖扭扭蛋',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 5,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 6,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking:7,
          name: '冰鲜柠檬水你喝嘛',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 8,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 9,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking: 10,
          name: '冰鲜柠檬水你喝嘛',
          watch: 12238980,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
      ],
      //第五周
      messages3 :[
        {
          ranking: 4,
          name: '崎岖扭扭蛋',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 5,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 6,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking:7,
          name: '冰鲜柠檬水你喝嘛',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 8,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 9,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking: 10,
          name: '冰鲜柠檬水你喝嘛',
          watch: 12238980,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
      ],
      //第六周
      messages4 :[
        {
          ranking: 4,
          name: '崎岖扭扭蛋',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 5,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 6,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking:7,
          name: '冰鲜柠檬水你喝嘛',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 8,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 9,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking: 10,
          name: '冰鲜柠檬水你喝嘛',
          watch: 12238980,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
      ],
      //第七周
      messages5 :[
        {
          ranking: 4,
          name: '崎岖扭扭蛋',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 5,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 6,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking:7,
          name: '冰鲜柠檬水你喝嘛',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 8,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 9,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking: 10,
          name: '冰鲜柠檬水你喝嘛',
          watch: 12238980,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
      ],
      //第八周
      messages6 :[
        {
          ranking: 4,
          name: '崎岖扭扭蛋',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 5,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 6,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking:7,
          name: '冰鲜柠檬水你喝嘛',
          watch: 122343,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
        {
          ranking: 8,
          name: '草莓冻干',
          watch: '456',
          image: 'https://picsum.photos/32/32',
        },
        {
          ranking: 9,
          name:'骨肉项链',
          watch: '78923',
          image: 'https://picsum.photos/id/237/32/32',
        },
        {
          ranking: 10,
          name: '冰鲜柠檬水你喝嘛',
          watch: 12238980,
          image: 'https://picsum.photos/seed/picsum/32/32',
        },
      ],
    }
  },
  created() {
    this.fetchData(); // 组件创建时调用 fetchData 方法
  },
  methods: {
    fetchData() {
      fetch('https://api.example.com/items') // 替换为你的实际 API URL
        .then(response => {
          if (!response.ok) {
            throw new Error('网络响应不是 OK');
          }
          return response.json();
        })
        .then(data => {
          this.items = data; // 将获取到的新数据赋值给 items
        })
        .catch(error => {
          console.error('发生错误:', error);
        });
    },
    back() {
      this.$router.push('/'); // 返回首页
    },
  }
}
</script>


<style lang="less" scoped>
.past {
  /deep/ .van-tabs__line {
    top: 28px;
    padding-bottom: 0 !important;
    background-color: #fff;
  }
  /deep/ .van-tabs{
    &__wrap{
      z-index: 20;
      display: flex;
      align-items: center;
      &::before{
        content: '';
        display: inline-block;
        width: 54px;
        height: 54px;
        margin-top: 10px;
        margin-left: 6px;
        background: url('~@/assets/images/zb.png') no-repeat;
        background-size: 100%;
      }
      .van-tab{
        font-size: 12px;
        color: #fff;
        background: linear-gradient(to bottom, #e06e53, #bc2212);
      }
    }

  }
  /deep/ .van-tabs__nav--line {
    height: 32px;
    margin-left: 10px;
    padding-bottom: 0 !important;
  }
  /deep/ .van-tabs--line .van-tabs__wrap{
    margin: 10px 0;
    border-radius: 10px;
  }
  /deep/ .van-tabs__nav--line.van-tabs__nav--complete {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .about-container {
    height: 260;
    background: url('~@/assets/images/bg.png') no-repeat top left / 100%;
  }
  .container-title{
    color: #fff;
    font-size: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
  }
  .container-title img{
    width: 14px;
    height: 18px;
    margin-right: 5px;
  }
  .container-title span{
    line-height: 24px;
  }
  .tab-pic{
    width: 180px;
    height: 36px;
    position: absolute;
    top: -16px;
    left: 80px;
  }
  .app-container{
    position: relative;
    width: 341px;
    margin: 4px auto;
    height: 534px;
    background: url('~@/assets/images/content1.png') no-repeat top left / 100%;
  }
  .container-top{
    ul{
      display: flex;
      justify-content: space-around;
      li{
        margin-top: 40px;
      }
      .ul-chidern1{
        position: relative;
        margin-top: 60px;
        width:106px;
        height:132px;
        background: url('~@/assets/images/top2.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern1-conent{
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 22px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
          }
        }
          span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 46px;
          margin-left: 6px;
          color: #550e00;
        }
        .ul-chidern1-conent::after{
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url('~@/assets/images/hg.png')no-repeat top left / 100%;
            position: absolute;
            top: -2px;
            left: 58px;
          }
      }
      .ul-chidern2{
        position: relative;
        margin-top: 40px;
        width:106px;
        height:144px;
        background: url('~@/assets/images/top1.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern2-conent{
          width: 54px;
          height: 54px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 24px;
          margin-top: 20px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
          }
        }
        span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 50px;
          margin-left: 6px;
          color: #550e00;
        }
        .ul-chidern2-conent::after{
            content: '';
            display: block;
            width: 30px;
            height: 30px;
            background: url('~@/assets/images/hg.png')no-repeat top left / 100%;
            position: absolute;
            top: 2px;
            left: 56px;
          }
      }
      .ul-chidern3{
        margin-right: 8px;
        margin-top: 60px;
        width:105px;
        height:138px;
        background: url('~@/assets/images/top3.png')no-repeat top left / 100%;
        margin-left: 6px;
        .ul-chidern3-conent{
          position: relative;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 24px;
          margin-top: 20px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
          p{
            font-size: 12px;
            text-align: center;
          }
        }
        span{
          display: block;
          width: 100%;
          text-align: left;
          color: #fff;
          font-size: 10px;
          margin-top: 40px;
          margin-left: 6px;
          color: #550e00;
        }
        .ul-chidern3-conent::after{
            content: '';
            display: block;
            width: 25px;
            height: 25px;
            background: url('~@/assets/images/hg.png')no-repeat top left / 100%;
            position: absolute;
            top: -16px;
            left: 34px;
          }
      }
    }
    }
  .container-center{
    color: #b04400;
    font-weight: 600;
    margin-top:18px;
    font-size: 16px ;
    ul{
      margin-left: 10px;
      width: 320px;
      display: flex;
      justify-content: space-around;
      padding-bottom: 10px;
      border-bottom: 1px dashed #b04400;
      li{
        display: flex;
        justify-items: center;
        margin-left: -14px;
      }

    }
  }
  .container-bottom{
    ul{
      overflow-y: auto;
      margin-left: 8px;
      margin-top: 10px;
      width: 324px;
      height: 290px;
      li {
        font-size: 13px;
        padding: 2px 0;
        span:nth-child(1) {
          text-align: center;
          width: 26px;
          display: inline-block;
        }
        span:nth-child(2) {
          display: inline-block;
          text-align: center;
          width: 90px;
          border-radius: 100%;
          img {
            width: 30px;
            height: 30px;
            border-radius: 100%;
            text-align: center;
          }
        }
        span:nth-child(3) {
          text-align: center;
          display: inline-block;
          width: 120px;
          padding-right: 12px;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;           /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
        }
        span:nth-child(4) {
          width: 60px;
          display: inline-block;
          text-align: left;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;           /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
        }
      }
      li:nth-child(even) {
        height: 32px;
        width: 100%; /* 偶数项背景色 */
        background-color: #f9dbbe;
      }
      li:nth-child(odd) {
        height: 34px;
        width: 100%;
 /* 偶数项背景色 */
      }
    }
  }
}
.back{
  position: absolute;
  top: -244px;
  left: 338px;
}
</style>