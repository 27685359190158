<template>
  <div class="about">
    <van-tabs>
      <van-tab title="昨日榜" class="home-container">
        <!-- <div class="app-container">
          <div class="container-top">
            <ul>
              <li :class="`ul-chidern${index + 1}`" v-for="(item, index) in rankingTop" :key="item.user_id">
                <div :class="`ul-chidern${index + 1}-conent`">
                  <img :src='item.avatar' alt="">
                </div>
                <p :class="`ul-chidern${index + 1}-name`">{{ item.user_nicename }}</p>
                <img src="~@/assets/images/z.png" alt="" class="ul-chidern1-img">
                <p class="ul-li-text">{{ item.income }}</p>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                </div>
                <p class="ul-chidern2-name">橙宝显示特价</p>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                </div>
                <p class="ul-chidern3-name">s 夏清</p>
              </li>
            </ul>
          </div>
          <div class="container-centent">
            <ul>
              <li class="container-centent-1">
                <img src="@/assets/images/ab-bg2.png" width="121px " height="112px" />
              </li>
              <li class="container-centent-2">
                <img src="@/assets/images/ab-bg1.png" width="136px" height="135px" />
              </li>
              <li class="container-centent-3">
                <img src="@/assets/images/ab-bg3.png" width="114px" height="104px" />
              </li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in otherRanking" :key="item.ranking" >
                <span>No.{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.income }}</span>
                <span class="conainer-bottom-text2">{{ item.user_nicename }}</span>
                <img src="~@/assets/images/z.png" alt="" class="conainer-bottom-pic2">
              </li>
            </ul>
          </div>
        </div> -->
      </van-tab>
      <van-tab title="上周榜" class="home-container">
        <!-- <div class="app-container">
          <div class="container-top">
            <ul>
              <li :class="`ul-chidern${index + 1}`" v-for="(item, index) in rankingTop" :key="item.user_id">
                <div :class="`ul-chidern${index + 1}-conent`">
                  <img :src='item.avatar' alt="">
                </div>
                <p :class="`ul-chidern${index + 1}-name`">昵称</p>
                <img src="~@/assets/images/z.png" alt="" class="ul-chidern1-img">
                <p class="ul-li-text">{{ item.income }}</p>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                </div>
                <p class="ul-chidern2-name">橙宝显示特价</p>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                </div>
                <p class="ul-chidern3-name">s 夏清</p>
              </li>
            </ul>
          </div>
          <div class="container-centent">
            <ul>
              <li class="container-centent-1">
                <img src="@/assets/images/ab-bg2.png" width="121px " height="112px" />
              </li>
              <li class="container-centent-2">
                <img src="@/assets/images/ab-bg1.png" width="136px" height="135px" />
              </li>
              <li class="container-centent-3">
                <img src="@/assets/images/ab-bg3.png" width="114px" height="104px" />
              </li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in otherRanking" :key="item.ranking" >
                <span>No.{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.income }}</span>
                <span class="conainer-bottom-text2">{{ item.user_nicename }}</span>
                <img src="~@/assets/images/z.png" alt="" class="conainer-bottom-pic2">
              </li>
            </ul>
          </div>
        </div> -->
      </van-tab>
      <van-tab title="上月榜" class="home-container">
        <!-- <div class="app-container">
          <div class="container-top">
            <ul>
              <li :class="`ul-chidern${index + 1}`" v-for="(item, index) in rankingTop" :key="item.user_id">
                <div :class="`ul-chidern${index + 1}-conent`">
                  <img :src='item.avatar' alt="">
                </div>
                <p :class="`ul-chidern${index + 1}-name`">昵称</p>
                <img src="~@/assets/images/z.png" alt="" class="ul-chidern1-img">
                <p class="ul-li-text">{{ item.income }}</p>
              </li>
              <li class="ul-chidern2">
                <div class="ul-chidern2-conent">
                  <img src="https://picsum.photos/id/237/54/54" alt="">
                </div>
                <p class="ul-chidern2-name">橙宝显示特价</p>
              </li>
              <li class="ul-chidern3">
                <div class="ul-chidern3-conent">
                  <img src="https://picsum.photos/seed/picsum/54/54" alt="">
                </div>
                <p class="ul-chidern3-name">s 夏清</p>
              </li>
            </ul>
          </div>
          <div class="container-centent">
            <ul>
              <li class="container-centent-1">
                <img src="@/assets/images/ab-bg2.png" width="121px " height="112px" />
              </li>
              <li class="container-centent-2">
                <img src="@/assets/images/ab-bg1.png" width="136px" height="135px" />
              </li>
              <li class="container-centent-3">
                <img src="@/assets/images/ab-bg3.png" width="114px" height="104px" />
              </li>
            </ul>
          </div>
          <div class="container-bottom">
            <ul>
              <li v-for="item in otherRanking" :key="item.ranking" >
                <span>No.{{item.ranking}}</span>
                <span class="conainer-bottom-pic">
                  <img :src="item.avatar" alt='' />
                </span>
                <span>{{ item.income }}</span>
                <span class="conainer-bottom-text2">{{ item.user_nicename }}</span>
                <img src="~@/assets/images/z.png" alt="" class="conainer-bottom-pic2">
              </li>
            </ul>
          </div>
        </div> -->
      </van-tab>
    </van-tabs> 
  </div>
</template>
<script>
import  Past from '@/components/past.vue'
export default {
  name: 'About',
  components: {
    Past
  },
  data() {
     return {
      // 观看
      // userList :[
      //   {
      //     types: '观看量',
      //     quantity: '100000',
      //   },   
      //   {
      //     types: '观看量',
      //     quantity: '130000',
      //   },
      //   {
      //     types: '观看量',
      //     quantity: '6000',
      //   }
      // ],
      // 第一周
      rankingTop: [], // 前三名
      otherRanking: [
      ],
       // 其他名次

    }
  },
  created() {
    this.fetchData(); // 组件创建时调用 fetchData 方法
  },
  methods: {
    fetchData() {
      fetch('/appapi/recording/list_zhou?type=month') // 替换为你的实际 API URL
        .then(response => {
          if (!response.ok) {
            throw new Error('网络响应不是 OK');
          }
          return response.json();
        })
        .then(data => {
          const items = JSON.parse(JSON.stringify(data.data));
          const rankingTop = items.length > 3 ? items.splice(0, 3) : items

          this.rankingTop = rankingTop
          this.otherRanking = items.length > 3 ? items : []
          
        })
        .catch(error => {
          console.error('发生错误:', error);
        });
    }
  },
};
</script>

<style lang="less" scoped>
.about {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #6170fd;
  .van-tabs {
    margin-top: 20px;
    margin: 0 auto;
  }
  /deep/ .van-tab {
    color: #972300;
    font-size: 18px;
    font-weight: 500;
    &--active{
      .van-tab__text.van-tab__text--ellipsis{
        color: #fff;
      }
    }
    &__text{
      color: #fff;
      position: relative;
      z-index: 2;
    }
  }
  /deep/ .van-tabs--line .van-tabs__wrap{
    margin: 0 auto;
    width: 343px;
    height: 42px !important;
    border: 1px solid #f79f2b;
    border-radius: 6px;
  }
  /deep/ .van-tabs__line {
    background-color: #f79f2b;
    width: 86px;
    height: 30px;
    margin-bottom: 5px;
    padding-top: 2px;
  }
  /deep/ .van-tabs__nav{
    background-color: #f0000000;
  }
  // /deep/ .van-tab__text--ellipsis {
  //   color: #972300;
  // }
  .home-container {
    padding-top: 34px;
    margin-bottom: 2px;
    background: url('~@/assets/images/bg.png') no-repeat top left / 100%;
  }
  .app-container{
    position: relative;
  }
  .container-top{
    width: 340px;
    margin: 0 auto;
    ul{
      display: flex;
      justify-content: space-around;
      li{
        margin-top: 40px;
        .ul-chidern1-img{
        width: 40px;
        height: 20px;
        display: block;
        padding: 3px 0;
        margin: 0 auto;
        }
      }
      .ul-chidern1{
        color:#fff;
        position: relative;
        margin-top: 60px;
        .ul-chidern1-conent{
          width: 64px;
          height: 64px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 10px;
          margin-top: -27px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
        .ul-chidern1-name {
          padding-left: 10px;
          margin-top: 3px;
          text-align: center;
          padding: 4px 0px;
          font-size: 14px;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;          /* 隐藏超出部分 */
          text-overflow: ellipsis;   /* 显示省略号 */
          width: 86px;   
        }
        .ul-chidern1-conent-title{
          width: 105px;
          padding-top: 30px;
          text-align: left;
          margin-left: 0 !important;
          span {
          font-size: 10px;
          color: #fff;
          }
        }
        .ul-chidern1-conent::after{
            content: '';
            display: block;
            width: 30px;
            height: 3em;
            background: url('~@/assets/images/ab-hg2.png')no-repeat top left / 100%;
            position: absolute;
            top: -48px;
            left: 30px;
          }
      }
      .ul-chidern2{
        color: #fff;
        position: relative;
        margin-top: 40px;
        .ul-chidern2-conent{
          width: 80px;
          height: 80px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 10px;
          margin-top: -32px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
        .ul-chidern2-name {
          margin-top: 3px;
          text-align: center;
          padding: 4px 6px;
          font-size: 14px;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;          /* 隐藏超出部分 */
          text-overflow: ellipsis;   /* 显示省略号 */
          width: 86px;   
        }
        .ul-chidern2-conent::after{
            content: '';
            display: block;
            width: 45px;
            height: 45px;
            background: url('~@/assets/images/ab-hg1.png')no-repeat top left / 100%;
            position: absolute;
            top: -64px;
            left: 30px;
          }
      }
      .ul-chidern3{
        color: #fff;
        .ul-chidern3-conent{
          position: relative;
          width: 50px;
          height: 50px;
          border-radius: 100%;
          border:1px solid #fff;
          margin-left: 24px;
          margin-top: 14px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
          }
        }
        .ul-chidern3-name {
          padding:0 4px;
          text-align: center;
          margin-top: 6px;
          font-size: 14px;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;          /* 隐藏超出部分 */
          text-overflow: ellipsis;   /* 显示省略号 */
          width: 86px;   
        }
        .ul-chidern3-conent::after{
            content: '';
            display: block;
            width: 28px;
            height: 28px;
            background: url('~@/assets/images/ab-hg3.png')no-repeat top left / 100%;
            position: absolute;
            top: -19px;
            left:10px;
          }
      }
      .ul-li-text{
        text-align: center;
        font-size: 12px;
        color: #e3dfdf;
        margin-bottom: 10px;
      }
    }
    }
  .container-centent {
    ul {
      display: flex;
      gap: 2px;
      .container-centent-1 {
        padding-top: 14px;
      }
      .container-centent-3 {
        padding-top: 24px;
      }
    }
  }
  // .container-title::after{
  //   content: '';
  //   display: block;
  //   width: 40px;
  //   height: 40px;
  // }

  .container-bottom{
    width: 365px;
    border-radius: 10px;
    height: 300px;
    margin: 0 auto;
    overflow: scroll;
    background: url('~@/assets/images/bm.png')no-repeat top left / 100%;

    ul{
      margin: 0 auto;
      overflow-y: auto;
      width: 324px;
      height: 295px;
      li {
        padding:8px 0;
        position: relative;
        border-bottom: 1px solid #e3dfdf;
        font-size: 13px;
        span:nth-child(1) {
          padding: 2px 0;
          margin-left: 12px;
          text-align: center;
          width: 26px;
          display: inline-block;
        }
        span:nth-child(2) {
          display: inline-block;
          text-align: center;
          width: 100px;
          border-radius: 100%;
          img {
            padding: 4px 0;
            width: 30px;
            height: 30px;
            border-radius: 100%;
            text-align: center;
          }
        }
        span:nth-child(3) {
          font-size: 12px;
          text-align: left;
          display: inline-block;
          width: 120px;
          padding-right: 12px;
          white-space: nowrap;        /* 不换行 */
          overflow: hidden;           /* 隐藏溢出的内容 */
          text-overflow: ellipsis;
          color: #8c8484;
        }
        .container-bottom-pic2 {
          width: 40px;
          height: 20px;
          display: block;
          }
        .conainer-bottom-text2 {
          display: inline-block;
          position: absolute;
          top:13px;
          left: 140px;
          font-size: 14px;
          padding-top: 2px;
          padding-bottom: 2px;
         }
        }
      li:last-child{
        border-bottom: none;
      }
    }
  }
}
</style>