<template>
  <div class="home">
    <div class="header">
      <img src="@/assets/images/home-bg.png" alt="header" class="header-img">
      <div class="activity-time"></div>
      <div class="rule-btn" @click="showRule = !showRule">
        规则
      </div>
    </div>
    <div class="tab-container">
      <div class="tab-wrapper">
        <div 
          class="tab-button" 
          :class="{ active: activeTab === 'recharge' }"
          @click="activeTab = 'recharge'; showRule = false"
        >
          充值享豪礼
        </div>
        <div 
          class="tab-button"
          :class="{ active: activeTab === 'welfare' }"
          @click="activeTab = 'welfare'; showRule = false"
        >
          累计充值礼上礼
        </div>
      </div>
      <div class="content-area">
        <div v-if="!showRule">
          <div v-if="activeTab === 'recharge'" class="tab-content">
            <img src="@/assets/images/充值享好礼.png" alt="充值内容">
          </div>
          <div v-if="activeTab === 'welfare'" class="tab-content">
            <img src="@/assets/images/累计充值礼上礼.png" alt="福利内容">
          </div>
        </div>
        <div v-else class="rule-content">
          <img src="@/assets/images/活动规则.png" alt="活动规则">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHome',
  data() {
    return {
      activeTab: 'recharge',
      showRule: false
    }
  }
}
</script>

<style lang="less" scoped>
.home {
  background: rgb(255, 171, 188);
  min-height: 100vh;
  
  .header {
    position: relative;
    width: 100%;
    
    .header-img {
      width: 100%;
      display: block;
    }
    
    .activity-time {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      text-align: center;
      color: #fff;
      font-size: 14px;
    }

    .rule-btn {
      position: fixed;
      top: 30%;
      right: 0;
      transform: translateY(-50%);
      writing-mode: vertical-lr;
      background: #FF4F4F;
      color: #fff;
      padding: 15px 8px;
      font-size: 16px;
      cursor: pointer;
      border-radius: 8px 0 0 8px;
      z-index: 100;
      
      &:hover {
        opacity: 0.9;
      }
    }
  }
  
  .tab-container {
    padding: 0 16px;
    margin-top: 3px;
    position: relative;
    z-index: 10;
    
    .tab-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      padding: 0 10px;
    }
    
    .tab-button {
      width: 150px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      border-radius: 22px;
      background: #fff;
      color: #FF4F4F;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      transition: all 0.3s ease;
      border: none;
      box-shadow: 0 2px 6px rgba(255, 79, 79, 0.1);
      
      &.active {
        background: linear-gradient(to right, #FF6B6B, #FF4F4F);
        color: #fff;
      }
      
      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 4px 8px rgba(255, 79, 79, 0.2);
      }
    }
    
    .content-area {
      margin-top: 20px;
      
      .tab-content {
        img {
          width: 100%;
          display: block;
        }
      }
      .rule-content {
        img {
          width: 100%;
          display: block;
        }
      }
    }
  }
}
</style>